.flexbox {
    height: 100vh;
    display: flex;
    align-items: center;
    flex-direction: column;
}

.text-identification-corporate-name {
    align-items: center;
    font-weight: bold;
    width: 100%;
    display: flex;
    font-size: x-large;
    font-family: Helvetica, sans-serif;
    height: 80px;
}

.step {
    height: 200px;
    width: 100%;
} 

.text-step-message {
    font-weight: bold;
}

.end-message {
    width: 100%;
    margin: auto auto;
    font-family: Helvetica, sans-serif;
    margin-top: initial;
    height: 80px;
    align-items: center;
}

