.timeline-wrapper {
     z-index: 2;
}

.timeline-steps-wrapper {
    width: fit-content;
    margin: auto;
    display: flex;
    align-items: center;
    width: 73%;
}

@media only screen and (max-width: 600px) {

    .timeline-wrapper {
        width: 100%;
        z-index: 0;
    }

    .timeline-steps-wrapper {
        width: 80%;
        margin: auto;
        display: flex;
        width: 100%;
    }
    
    .scroll {
        width: 635px;
        height: 300px;
        overflow-y: auto;
        overflow-x: hidden;
    }

    .scroll > div {
        width: 100px;
        height: 100px;
    }
}