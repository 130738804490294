.code-page__image_responsive {
    width: 100%;
    max-width: 200px;
    max-height: 200px;
    height: auto;
}

.code-page__instruction {
    font-size: 34px;
}

.code-page__instruction_space {
    text-align: center;
}

.code-page__image {
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin-bottom: 20px;
}

.code-page__image_space {
    margin-bottom: 20px
}

.code-page__code {
    display: flex;
    justify-content: center;
}

.code-page__code_space {
    padding-top: 50px;
}

.code-page__code_space-error {
    padding-top: 29px;
}

.align-column {
    display: flex;
    flex-direction: column;
    align-content: center;
    align-items: center;
}

.input-error_center {
    display: flex;
    align-content: center;
    align-items: center;
    align-self: center;
}

.code-page__code_error {
    display: flex;
    flex-direction: column;
    align-content: center;
    align-items: center;
    margin: 15px auto 15px auto;
}

#partitioned {
    background-color: #fafafa;
    padding-left: 10px;
    letter-spacing: 46px;
    border: 0;
    background-image: linear-gradient(to left, black 65%, rgba(255, 255, 255, 0) 0%);
    background-position: bottom;
    background-size: 90px 2px;
    background-repeat: repeat-x;
    background-position-x: 65px;
    width: 362px;
    min-width: 220px;
    font-size: 80px;
    color: #202121;
    margin-bottom: 15px;
}

#partitioned:hover {
    cursor: pointer;
}

@keyframes treme {
    0% {margin-left: 0;}
    25% {margin-left: 7px;}
    50% {margin-left: 0;}
    75% {margin-left: -5px;}
    100% {margin-left: 0;}
  }

.error-partitioned {
    background-image: linear-gradient(to left, #f44336 65%, rgba(255, 255, 255, 0) 0%) !important;
    animation: treme 0.1s;
    animation-iteration-count: 4;
}

#partitioned:focus {
    box-shadow: 0 0 0 0;
    border: 0 none;
    outline: 0;
}

#divInner {
    left: 0;
    position: sticky;
}

#divOuter {
    width: 362px;
    overflow: hidden;
    margin-left: 15px;
}

#partitioned::placeholder { /* Firefox, Chrome, Opera */ 
    opacity: 0.3; 
} 
  
#partitioned:-ms-input-placeholder { /* Internet Explorer 10-11 */ 
    opacity: 0.3; 
} 
  
#partitioned::-ms-input-placeholder { /* Microsoft Edge */ 
    opacity: 0.3; 
} 

.code-page__button {
    display: flex;
    justify-content: center;
}

.code-page__button_space {
    margin-bottom: 10px;
}

.code-page__buttons_space {
    display: flex;
    align-content: center;
    justify-content: center;
}

.code-page__white-spinner {
    display: flex;
    align-items: center;
}

.submit-loading-button-icon {
    width: 25px;
    height: 25px;
    padding-right: 5px;
}