.lista-documentos-wrapper{
    height: 20em;
    width: 100%;
}

.nome-usuario {    
    display: inline-block;
    height: 22px;
}

.wrapper-teste{
    height: 20em;
    width: 100%;
    background-color: gray;

    &> .lista-documentos-titulo {
        color: black;
        margin: 0px; 
        font-weight: bold;
        font-size: 24px;
    }

}

.usuario-wrapper {
    margin: 12px auto;
    width: 100%;

    & > .text-identificacao-usuario {
            color: #000000;
            font-family: Helvetica, sans-serif;
            font-size: 22px;
            line-height: 26px;
            margin-bottom: 16px;
            padding-top: 15px;

        & > span {
            font-weight: bold;
        }
    }

    & > .text-intrucoes-usuario {
            color: #000000;
            font-family: Helvetica, sans-serif;
            font-size: 18px;
            line-height: 22px;            
            margin-bottom: 32px;
    }
}

.tabela-header {
        display: flex;
        background-color: #F2F2F2;
}

.aceite-termos-wrapper {
    width: 100%;
    display: flex;
    margin: 24px auto;
}

.aceite-termos-checkbox{
    margin: auto 16px auto auto;
    min-width: 16px;
    max-width: 16px;
    cursor: pointer;

    & > path {
        fill: #71BA8D;
    }
}

.accept-text-terms {
    text-align: justify;
    cursor: default;
}

.tabela-documentos {
    margin: auto auto;
    width: 80%;
}

.documentos-titulo {
    margin: auto 56px;
}

.header-documentos {
    margin-left: 221px;
}

.header-status {
    margin:  auto 108px auto auto;
}

.finalizar-botao-wrapper {
    width: 100%;
    height: 40px;
    border-radius: 6px;
    margin: auto auto 24px auto;
    display: flex;
}

.finalizar-botao-ativo {    
    cursor: pointer;
    background-color: #71BA8D;
}

.finalizar-botao-inativo {   
    background-color: #9E9E9E;
}


.finalizar-botao-texto {
    color: white;
    margin: auto auto auto 4px;
    font-weight: bold;
}

.finalizar-botao-icon {
    margin: auto 4px auto auto;
    width: 16px;
    & > path {
        fill: white;
    }
}

.submit-loading-botao-icon {
    margin: auto 4px auto auto;
    width: 35px;
}

.painel-wrapper {
    margin: auto;
}

.button-end {
    height: 65px;
}