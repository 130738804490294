html {
    height: 100%;
}

body {
    height: 100%;
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

#root {
    background-color: #FAFAFA;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

.content-page {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 90%;
}

.content-page__inner {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.fade-in_3s {
    -webkit-animation: fadeIn 3.0s ease-in-out;
    -moz-animation: fadeIn 3.0s ease-in-out;
    -o-animation: fadeIn 3.0s ease-in-out;
    animation: fadeIn 3.0s ease-in-out;
}

.fade-in_4-8s {
    -webkit-animation: fadeIn 4.8s ease-in-out;
    -moz-animation: fadeIn 4.8s ease-in-out;
    -o-animation: fadeIn 4.8s ease-in-out;
    animation: fadeIn 4.8s ease-in-out;
}


.fade-in_7s {
    -webkit-animation: fadeIn 7s ease-in-out;
    -moz-animation: fadeIn 7s ease-in-out;
    -o-animation: fadeIn 7s ease-in-out;
    animation: fadeIn 7s ease-in-out;
}

@-webkit-keyframes fadeIn {
    0% {
        opacity: 0;
    }
    70% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

@-moz-keyframes fadeIn {
    0% {
        opacity: 0;
    }
    70% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

@-o-keyframes fadeIn {
    0% {
        opacity: 0;
    }
    70% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

@keyframes fadeIn {
    0% {
        opacity: 0;
    }
    70% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}


.dynamic-text:after {
    content: '|';
    opacity: 0;
    animation: pisca 1.2s 3;
}

@keyframes pisca {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 0;
    }
    50% {
        opacity: 1;
    }
}

.appear_5s:after {
    content: ':';
    opacity: 1;
    animation: appear 5.5s 1;  
}

.appear_4s:after {
    content: ':';
    opacity: 1;
    animation: appear 4.5s 1;  
}

@keyframes appear {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
    50% {
        opacity: 0;
    }
}

.page_background {
    background-color: #fafafa;
}