.timeline-step-wrapper {
    display: grid;
    width: 5%;
    margin: auto 0px auto 0px;
    padding: 50px;
}

.timeline-step-circle-wrapper {
    width: 2em;
    height: 2em;
    text-align: center;
    line-height: 2em;
    border-radius: 4em;
    background: #8C763E;
    margin: 0 2em;
    display: inline-block;
    color: white;
    position: sticky;
    border: 0.5em solid #8C763E;
}

.timeline-step-circle-wrapper-first::before {
    content: '';
    position: absolute;
    top: .8em;
    width: 8.5em;
    left: -10.5em;
    height: .5em;
    background: #8C763E;
    margin: inherit;
    border-radius: 4px;
}

.timeline-step-circle-wrapper::after {
    content: '';
    position: absolute;
    top: .8em;
    right: -8.5em;
    width: 8.5em;
    height: .5em;
    background: #8C763E;
}

.timeline-step-circle-wrapper:first-child::before {
    display: none;
}

.timeline-step-circle-wrapper-checked {
    background: white;
    color: #8C763E;
}

.timeline-step-circle-wrapper-checked::before {
    background: #8C763E;
}

.timeline-step-name-wrapper {
    text-align: center;
    width: 100%;
    display: block;
    color: #8C763E;
    height: 50px;
}

.timeline-step-name-wrapper-after {
    color: #dddddd;
}

.timeline-step-circle-wrapper-after {
    background: white;
}

.timeline-step-circle-wrapper-checked~.timeline-step-name-wrapper {
    font-weight: bold;
}

.timeline-step-hr-wrapper {
    border: 0;
}

.timeline-step-loading-wrapper {
	width: 8em;
    height: 2em;
	background: linear-gradient(-90deg, rgba(92, 90, 83, 0.247) 0%, #FCFCFC 50%, #F0F0F0 100%);
	background-size: 400% 400%;
	animation: pulse 0.9s ease-in-out infinite;
	@keyframes pulse {
		0% {
		background-position: 0% 0%;
		}
		100% {
		background-position: -135% 0%;
		}
	}
}