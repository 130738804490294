.closeTag {
    text-align: end;
    font-weight: bold;
    font-size: 18px;
    margin-bottom: 20px;
}

.closeTag_cursor {
    cursor: pointer;
}

.modal {
    display: flex;
    align-items: center;
    justify-content: center;
    border: 0px;
}

.checkEmail__image {
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin-bottom: 20px;
}

.checkEmail__image_responsive {
    width: 100%;
    max-width: 200px;
    max-height: 200px;
    height: auto;
}

.checkEmail__title {
    text-align: center;
}

.checkEmail__instruction {
    text-align: center;
    font-size: 18px;
}

.checkEmail__button {
    display: flex;
    flex-direction: row;
    justify-content: center;
}

.checkEmail__button_inner {
    font-size: 20px;
}