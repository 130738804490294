.home-page__title {
    font-size: 52px;
    text-align: center;
    color: #2E2D27;
}

.home-page__subTitle {
    font-size: 36px;
    text-align: center;
    margin: 0px 20px 30px 20px;
    color: #2E2D27
}

.home-page__image_responsive {
    width: 100%;
    max-width: 400px;
    max-height: 400px;
    height: auto;
}

.home-page__image {
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin-bottom: 20px;
}

.textField {
    display: flex;
}

.submit-loading-button-icon {
    width: 25px;
    height: 25px;
    padding-right: 5px;
}

.home-page__white-spinner {
    display: flex;
    align-items: center;
}

.align-spinner {
    margin: auto 12px;
}

.home-page__button {
    flex-direction: row;
    justify-content: center;
    padding-left: 20px 
}

@media (max-width: 800px) {
    .textField {
        display: flex;
        flex-direction: column;
    }
    .home-page__button {
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
        padding-top: 30px;
    }
}