.mensagem-wrapper {
    margin: 25px auto;
    width: 100%;

    & > .text-mensagem {
            color: #000000;
            font-family: Helvetica, sans-serif;
            font-size: 20px;
            line-height: 22px;
            margin-bottom: 2px;
            font-weight: bold;
    }
}

.border-mensagem{
    margin: 10px auto;
    width: 85%;
    border-style: groove;
    border-bottom-width: 2px;
    border-top-width: 0;
    border-right-width: 0;
    border-left-width: 0;
}
.tabela-header {
        display: flex;
        background-color: #F2F2F2;
}

.unsubscribe-wrapper {
    width: 100%;
    display: flex;
    margin: 24px auto;
}

.unsubscribe-painel-wrapper {
    margin: auto;
    height: 100vh;
}

.unsubscribe-checkbox{
    margin: auto 16px auto auto;
    min-width: 16px;
    max-width: 16px;
    cursor: pointer;

    & > path {
        fill: #71BA8D;
    }
}

.unsubscribe-texto {
    text-align: justify;
}

.tabela-documentos {
    margin: auto auto;
    width: 100%;
}

.finalizar-botao-wrapper {
    width: 100%;
    height: 40px;
    border-radius: 6px;
    margin: auto auto 24px auto;
    display: flex;
}

.finalizar-botao-ativo {    
    cursor: pointer;
    background-color: #71BA8D;
}

.finalizar-botao-inativo {   
    background-color: #9E9E9E;
}


.finalizar-botao-texto {
    color: white;
    margin: auto auto auto 4px;
    font-weight: bold;
}

.finalizar-botao-icon {
    margin: auto 4px auto auto;
    width: 16px;
    & > path {
        fill: white;
    }
}

.ibcapital-link-wrapper {
    width: 85%;
    height: 40px;
    margin: auto auto 24px auto;
    display: flex;
    align-items: center;
}

.ibcapital-link-texto {
    color: rgb(97, 97, 112);
    margin: auto auto auto auto;
        & > .a-link{
            text-decoration:none;
            font-family: Helvetica, sans-serif;
            font-size: 14px;
        }
}