.title,
.subTitle {
    align-self: flex-start;
}

.title {
    font-size: 34px;
    margin: 20px 20px 20px 0px;
}

.container-grid {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: auto;
}

.image {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-right: 25px;
}

.register-page__title_space {
    display: flex;
    align-items: flex-start;
}

.responsive {
    width: 100%;
    max-width: 450px;
    height: auto;
}


.robot-button {
    display: grid;
    grid-template-columns: auto auto;
    width: 100%;
    margin-top: 10px;
}

.registerPage--button {
    display: flex;
    flex-direction: column;
    align-content: center;
    padding-top: 14px;
}

Button {
    align-self: flex-end;
}

.submit-loading-button-icon {
    width: 25px;
    height: 25px;
    padding-right: 5px;
}

.align-spinner {
    margin: auto 12px;
}

.button-right {
    text-align: right;
}

.register-page__white-spinner {
    display: flex;
    align-items: center;
}

@media (max-width: 1000px) {
    .container-grid {
        display: grid;
        grid-template-columns: 1fr;
    }
    .form {
        margin-top: 15px;
    }
    .image {
        align-items: center;
    }  
}

@media (max-width: 530px) {
    .robot-button {
        display: flex;
        flex-direction: column;
        align-content: flex-start;
        align-items: flex-start;
    }

    .button-out {
        margin-top: 15px;
        margin-bottom: 15px;
    } 
}