.error-input_out {
    display: flex;
    align-content: center;
    align-items: center;
    margin-top: 0px;
    margin-bottom: 6px;
}

.error-input__label {
    margin-top: 0px;
    padding-top: 0px;
    margin-left: 5px;
    font-size: 12px;
    color: #f44336;
}

.ic-close_size {
    width: 15px;
    height: 15px;
    color: #f44336;
}
