.header-inner-wrapper {
    display: flex;
    margin: auto auto;
}

.header-wrapper {
    width: 100%;
    height: 80px;
    background-color: #2E2D27;
    display: flex;
    z-index: 1;
}

.header-logo-wrapper {
    margin: auto auto auto 0px;
    
    background-image: url(../../Common/Assets/images/logo.jpg);    
    background-repeat: no-repeat;
    
    min-width: 250px;
    height: 60px;

    & > img {
        margin: auto;
        float: left;
    }
}

.contador-wrapper {
    margin: auto 0px auto auto;
    display: flex;
}

.contador-texto {
    color: #999999;
}

.contador-tempo {
    margin-left: 4px;
    color: white;
}

.contador-tempo-loading {
	width: 98px;
}

@media only screen and (max-width: 600px) {
    .header-wrapper {
        height: 100px;
    }

    .header-inner-wrapper {
        display: block;
        width: 85%;
    }

    .header-logo-wrapper {
        background-image: url(../../Common/Assets/images/logo.jpg);
        min-width: 250px;
        height: 60px;
        background-repeat: no-repeat;
        margin: 10px auto auto 0px;
    }

    .contador-wrapper {
        min-width: 200px;
        margin: 5px 0px auto 0px;
    }    
  }
  